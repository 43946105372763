<template>
  <div class="idverify-step2-content">
    <van-form class="px-12" ref="form">
      <van-field
        v-for="(item, key) in FormObj"
        :key="key"
        required
        v-model="userVertifiedRef[key]"
        input-align="right"
        :name="item.name"
        :label="item.label"
        :placeholder="!disabled ? '请输入' : '识别中...'"
        :disabled="disabled"
        :type="item.type"
        :autosize="item.autosize"
        :rules="item.rules"
        rows="1"
      />
    </van-form>
    <div v-if="userVertifiedRef.usage === 1" class="font-500 px-24">身份证照片</div>
    <div v-if="userVertifiedRef.usage === 1" class="px-24 py-8 id-card-wrap d-flex justify-space-between">
      <tos-uploader
        class="id-card-front id-card"
        @afterUploaded="afterUploadedCardFront"
        v-model="cardFrontFileList"
        :max-count="1"
        >上传身份证正面</tos-uploader
      >
      <tos-uploader class="id-card-back id-card" v-model="cardBackFileList" :max-count="1">上传身份证反面</tos-uploader>
    </div>
    <div v-else class="px-24">
      <div class="font-500 flex items-center">
        <div class="text-danger text-20" style="margin-right: 2px">*</div>
        行驶证主副页照片
      </div>
      <tos-uploader
        class="truck-card mt-8"
        style="width: 1.6rem;height: 1.26rem;"
        v-model="carlicenseFileList"
        @afterUploaded="afterUploadedTruckCard"
        :max-count="1"
        bgType="xingshizheng-zhu"
      />
    </div>
    <div class="mt-24" style="height:0.72rem;">
      <div class="btn-box">
        <van-button size="large" @click="handleToNext" type="primary">下一步</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import { useIdVerifiedStore } from '@/store/idVerify';
import { apiOcrIdCard, apiOcrVehicleLicense } from '@/api/ocr';
import { driverAuthStepFormOne, carAuthStepFormOne } from '../constants';
import { Toast } from 'vant';

export default defineComponent({
  components: {
    TosUploader,
    Toast
  },
  setup(_, ctx) {
    const userVerifiedStore = useIdVerifiedStore();
    const FormObj = reactive(userVerifiedStore.verifiedInfo.usage === 1 ? driverAuthStepFormOne : carAuthStepFormOne);
    const disabled = ref(false);

    const cardFrontFileList = ref([]);
    const cardBackFileList = ref([]);
    const carlicenseFileList = ref([]);

    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);

    onMounted(() => {
      // 如果缓存中有图片需要进行回填
      if (userVerifiedStore?.verifiedInfo?.driverIdCardFrontFilename) {
        cardFrontFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.driverIdCardFrontFilename || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.driverIdCardBackFilename) {
        cardBackFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.driverIdCardBackFilename || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseFilename) {
        carlicenseFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseFilename || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
    });

    const handleToNext = async () => {
      await ctx.refs.form?.validate();

      // 去下一步对数据进行缓存 需要同步到storage中
      if (userVertifiedRef.value.usage === 1) {
        userVerifiedStore.updateInfo({
          ...userVertifiedRef.value,
          driverIdCardFrontFilename: cardFrontFileList?.value[0]?.fileName,
          driverIdCardBackFilename: cardBackFileList?.value[0]?.fileName,
          step: 2
        });
      } else {
        if (!carlicenseFileList?.value[0]?.fileName) {
          Toast.fail('行驶证主副页照片不能为空');
        } else {
          userVerifiedStore.updateInfo({
            ...userVertifiedRef.value,
            drivingLicenseFilename: carlicenseFileList?.value[0]?.fileName,
            step: 2
          });
        }
      }
    };

    async function afterUploadedCardFront(file) {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrIdCard({ frontImageUrl: file.url });
        const { data } = res;
        const { cardNumber, name } = data;
        userVerifiedStore.updateInfo({
          driverIdCard: cardNumber,
          driverName: name
        });
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    }

    const afterUploadedTruckCard = async file => {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrVehicleLicense({ imageUrl: file.url });
        const { data } = res;
        const { carNumber } = data;
        userVerifiedStore.updateInfo({
          carLicensePlate: carNumber
        });
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    };

    return {
      FormObj,
      cardFrontFileList,
      cardBackFileList,
      afterUploadedCardFront,
      handleToNext,
      userVertifiedRef,
      disabled,
      carlicenseFileList,
      afterUploadedTruckCard
    };
  }
});
</script>

<style lang="less">
.idverify-step2-content {
  .id-card-wrap {
    .id-card {
      width: 1.6rem;
      height: 1.26rem;
    }

    .id-card-front {
      .tos-uploader-bg {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/zhengjiantuli/shenfenzhengrenxiangmian@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .id-card-back {
      .tos-uploader-bg {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/zhengjiantuli/shenfenzhengguohuiye@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
